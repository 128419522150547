<template>

    <div class="contrainer-right" v-loading='loading'>
      <custom-actions class="card" title="设备信息">
        <div slot="header" v-if="detailInfo">
          <el-button v-if="permission.edit" type="text" @click="onEdit(detailInfo.deviceNum)">编辑</el-button>
          <el-button v-if="permission.del" class="del-btn" type="text" @click="onDelete(detailInfo.deviceNum)">删除</el-button>
          <el-button type="text" @click="goHistory(detailInfo.deviceNum)">历史数据</el-button>
        </div>
        <div v-if="detailInfo">
          <div class="title-box">基础信息</div>
          <table
            class="content-table"
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <!-- <tr>
              <td>设备名称：</td>
              <td>
                {{ detailInfo.deviceName || "--" }}
              </td>
              <td>所属基站：</td>
              <td>
                {{ detailInfo.stationName || "--" }}
              </td>
            </tr> -->
            <tr>
              <td>基站外空开串号：</td>
              <td>
                {{ detailInfo.deviceNum || "--" }}
              </td>
              <td>基站内空开串号：</td>
              <td>
                {{ detailInfo.insideDeviceNum || "--" }}
              </td>
            </tr>
            <tr>
              <td>基站外电表读数：</td>
              <td>
                <span class="font-big">{{ detailInfo.deviceNew.electricityValue || "--" }}</span>
              </td>
              <td>基站内电表读数：</td>
              <td>
                <span class="font-big">{{ detailInfo.deviceNew.insideElectricityValue || "--" }}</span>
              </td>
            </tr>
            <tr>
              <td>基站外开关状态：</td>
              <td>
                <!-- {{ detailInfo.deviceNew.deviceState === '1' || "--" }} -->
                <el-tag v-if="detailInfo.deviceNew.deviceState === '1'" type="success">开</el-tag>
                <el-tag v-else type="danger">关</el-tag>
              </td>
              <td>基站内开关状态：</td>
              <td>
                <el-tag v-if="detailInfo.deviceNew.insideDeviceState === '1'" type="success">开</el-tag>
                <el-tag v-else type="danger">关</el-tag>
              </td>
            </tr>
          </table>

          <!-- <div class="table-bx">
            <div class="left">
              <div class="title-box title-box2">基站外设备信息</div>
              <table
                class="content-table"
                border="0"
                cellspacing="0"
                cellpadding="0"
              >
                <tr>
                  <td>基站外设备分合闸：</td>
                  <td>
                    <div>
                      <el-switch
                        v-model="detailInfo.deviceNew.showSwitch"
                        :disabled="detailInfo.deviceNew.deviceDel == '2'"
                        @change="
                          changeSwitch(
                            detailInfo.deviceNum,
                            detailInfo.deviceNew.deviceState
                          )
                        "
                      ></el-switch>

                      <span class="ml10">{{
                        detailInfo.deviceNew.deviceState == "1"
                          ? "合闸"
                          : "分闸"
                      }}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>基站外设备状态：</td>
                  <td>
                    <el-tag
                      type="success"
                      v-if="detailInfo.deviceNew.deviceDel == '1'"
                      >在线</el-tag
                    >
                    <el-tag
                      type="danger"
                      v-else-if="detailInfo.deviceNew.deviceDel == '2'"
                      >离线</el-tag
                    >
                    <span v-else>--</span>
                  </td>
                </tr>
                <tr>
                  <td>基站外设备实时电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.deviceAmpere || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外漏电电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.ldData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外A相电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.laData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外B相电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.lbData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外C相电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.lcData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外A相电压：</td>
                  <td>
                    {{ detailInfo.deviceNew.vaData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外B相电压：</td>
                  <td>
                    {{ detailInfo.deviceNew.vbData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外C相电压：</td>
                  <td>
                    {{ detailInfo.deviceNew.vcData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外A相温度：</td>
                  <td>
                    {{ detailInfo.deviceNew.waData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外B相温度：</td>
                  <td>
                    {{ detailInfo.deviceNew.wbData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站外C相温度：</td>
                  <td>
                    {{ detailInfo.deviceNew.wcData || "--" }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="right">
              <div class="title-box title-box2">基站内设备信息</div>
              <table
                class="content-table"
                border="0"
                cellspacing="0"
                cellpadding="0"
              >
                <tr>
                  <td>基站内设备分合闸：</td>
                  <td>
                    <div>
                      <el-switch
                        v-model="detailInfo.deviceNew.insideShowSwitch"
                        :disabled="detailInfo.deviceNew.insideDeviceDel == '2'"
                        @change="
                          changeInsideSwitch(
                            detailInfo.deviceNum,
                            detailInfo.deviceNew.insideDeviceState
                          )
                        "
                      ></el-switch>
                      <span class="ml10">{{
                        detailInfo.deviceNew.insideDeviceState == "1"
                          ? "合闸"
                          : "分闸"
                      }}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>基站内设备状态：</td>
                  <td>
                    <el-tag
                      type="success"
                      v-if="detailInfo.deviceNew.insideDeviceDel == '1'"
                      >在线</el-tag
                    >
                    <el-tag
                      type="danger"
                      v-else-if="detailInfo.deviceNew.insideDeviceDel == '2'"
                      >离线</el-tag
                    >
                    <span v-else>--</span>
                  </td>
                </tr>
                <tr>
                  <td>基站内设备实时电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideDeviceAmpere || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内漏电电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideLdData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内A相电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideLaData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内B相电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideLbData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内C相电流：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideLcData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内A相电压：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideVaData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内B相电压：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideVbData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内C相电压：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideVcData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内A相温度：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideWaData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内B相温度：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideWbData || "--" }}
                  </td>
                </tr>
                <tr>
                  <td>基站内C相温度：</td>
                  <td>
                    {{ detailInfo.deviceNew.insideWcData || "--" }}
                  </td>
                </tr>
              </table>
            </div>
          </div> -->
          <div class="title-box title-box2">
            基站内外电流差值：
              <span class="font-big">
                {{Math.abs((detailInfo.deviceNew.deviceAmpere || 0) - (detailInfo.deviceNew.insideDeviceAmpere || 0))}}
              </span>
          </div>
          <div class="title-box title-box2">
            防窃电参数：
            <span class="font-big">{{detailInfo.antiStealing}}</span>
            <span class="tips">提示：偷电报警触发的电流差值，0代表关闭状态</span>
          </div>

          <div class="title-box title-box2">
            内空开直流电压：
            <span class="font-big">{{detailInfo.deviceNew.v46Value || '--'}}V</span>
          </div>

          <div class="title-box title-box2">
            内空开参数：
            <template v-if="detailInfo.v461 === '0' && detailInfo.v462 === '0'">
              <span class="font-big">{{detailInfo.v461}}V</span>
            </template>
            <template v-else>
              <span class="font-big">{{detailInfo.v461}}V</span>
              关闭时间
              <span class="font-big">{{detailInfo.v462}}小时</span>
            </template>
            <span class="tips">提示：内空开电压参数，0代表撤防状态</span>
          </div>

          <!-- <div class="title-box title-box2">保护设置</div>
          <table
            class="content-table"
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <tr>
              <td>瞬时保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.instantaneous == '0'
                      ? 'close-style'
                      : 'open-style'
                  "
                ></div>
              </td>
              <td>短延时保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.shortDelay == '0' ? 'close-style' : 'open-style'
                  "
                ></div>
              </td>
            </tr>

            <tr>
              <td>过载保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.overload1 == '0' &&
                    detailInfo.overload2 == '0' &&
                    detailInfo.overload3 == '0' &&
                    detailInfo.overload3 == '0'
                      ? 'close-style'
                      : 'open-style'
                  "
                ></div>
              </td>
              <td>漏电保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.leakageAction == '0'
                      ? 'close-style'
                      : 'open-style'
                  "
                ></div>
              </td>
            </tr>
            <tr>
              <td>过电压保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.overvoltage == '0' ? 'close-style' : 'open-style'
                  "
                ></div>
              </td>
              <td>欠电压保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.underVoltage == '0'
                      ? 'close-style'
                      : 'open-style'
                  "
                ></div>
              </td>
            </tr>
            <tr>
              <td>超温保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.overTemperature == '0'
                      ? 'close-style'
                      : 'open-style'
                  "
                ></div>
              </td>
              <td>拉弧保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.arc1 == '0' && detailInfo.arc2 == '0'
                      ? 'close-style'
                      : 'open-style'
                  "
                ></div>
              </td>
            </tr>
            <tr>
              <td>缺相保护：</td>
              <td>
                <div
                  :class="
                    detailInfo.openPhase == '0' ? 'close-style' : 'open-style'
                  "
                ></div>
              </td>
              <td>防偷电电流差值：</td>
              <td>
                <div
                  :class="
                    detailInfo.antiStealing == '0'
                      ? 'close-style'
                      : 'open-style'
                  "
                ></div>
              </td>
            </tr>
          </table> -->

          <!-- <div class="title-box title-box2">偷电告警</div> -->
          <el-tabs class="tabs" v-model="currentRealTime" @tab-click="loadData">
            <el-tab-pane label="偷电告警" name="1"></el-tab-pane>
            <el-tab-pane label="其他电力告警" name="2"></el-tab-pane>
          </el-tabs>
          <el-table stripe size="mini" border v-loading="tableLoading" :data="tableData">
            <el-table-column min-width="150" label="告警类型" prop="msgTypeName"></el-table-column>
            <el-table-column min-width="150" label="告警时间" prop="ctime"></el-table-column>
            <!-- <el-table-column min-width="150" label="解除时间" prop="relieveTime"></el-table-column> -->
            <el-table-column min-width="200" show-overflow-tooltip label="消息内容" prop="msg"></el-table-column>
            <!-- <el-table-column min-width="120" show-overflow-tooltip label="告警状态">
              <template v-slot="{ row }">{{row.msgState | msgStateName}}</template>
            </el-table-column> -->
          </el-table>

          <el-pagination
            class="mt10"
            :current-page.sync="tablePage"
            background
            layout="total, prev, pager, next"
            :total="tableTotal"
            @current-change="loadData"
          >
          </el-pagination>
        </div>
      </custom-actions>
    </div>

</template>

<script>
import { filterEmpyKey } from '@/utils/utils.js'

export default {
  name: 'DeviceDetailPage',

  data () {
    return {
      loading: false,

      searchForm: {
        stationId: '',

        parentDeptId: ''
      },
      deptList: [],
      detailInfo: null,
      page: 1,
      size: 10,
      total: 0,

      tableData: [],
      tablePage: 1,
      tableTotal: 0,
      tableLoading: false,
      currentRealTime: '1'
    }
  },
  computed: {
    permission () {
      return this.$store.state.permission
    }
  },
  filters: {
    msgStateName (value) {
      switch (value) {
        case '0': return '待确认'
        case '1': return '待恢复'
        case '2': return '已恢复'
        default: return '未知'
      }
    }
  },
  created () {

  },
  watch: {
    $route: {
      handler: function (newV) {
        this.searchForm.stationId = newV.params.deptId
        this.searchForm.parentDeptId = newV.params.parentDeptId
        this.getTableData().then(() => {
          this.loadData()
        })
      },
      immediate: true
    }

  },
  methods: {
    loadData () {
      this.tableLoading = true
      this.$axios.get(this.$apis.device.selectMsgByPage, {
        params: {
          page: this.tablePage,
          realTime: this.currentRealTime,
          stationId: this.detailInfo.stationId,
          showTimeM: '1'
        }
      }).then(res => {
        this.tableData = res.list
        this.tableTotal = res.total
      }).finally(() => {
        this.tableLoading = false
      })
    },
    changeSwitch (deviceNum, type) {
      console.log(deviceNum, type)
      this.detailInfo.deviceNew.showSwitch = !this.detailInfo.deviceNew.showSwitch

      this.changeDeviceState(deviceNum, type)
    },
    changeInsideSwitch (deviceNum, type) {
      console.log(deviceNum, type)
      this.detailInfo.deviceNew.insideShowSwitch = !this.detailInfo.deviceNew.insideShowSwitc
      this.changeDeviceState(deviceNum, type)
    },
    changeDeviceState (deviceNum, type) {
      console.log('type', type)
      const text = type === '0' ? '合闸' : type === '1' ? '分闸' : ''
      this.$confirm('确定' + text + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        if (type === '0') {
          // 合闸
          this.$axios.post(this.$apis.device.openDevice, { deviceNum }).then(res => {
            this.page = 1
            setTimeout(() => {
              this.loading = false
              this.$message.success('设备设置合闸成功')

              this.getTableData()
            }, 5000)
          })
        } else if (type === '1') {
          // 分闸
          this.$axios.post(this.$apis.device.closeDevice, { deviceNum }).then(res => {
            this.page = 1
            setTimeout(() => {
              this.loading = false
              this.$message.success('设备设置分闸成功')
              this.getTableData()
            }, 5000)
          })
        }
      }).catch(() => {

      })
    },

    getTableData () {
      this.loading = true
      return this.$axios.get(this.$apis.device.selectDeviceByList, {
        params: {
          page: this.page,
          size: this.size,
          ...filterEmpyKey(this.searchForm)
        }
      }).then(res => {
        if (res) {
          if (res.list && res.list.length > 0) {
            res.list.forEach(item => {
              item.overload1 = item.overload.split('_')[0] || 0
              item.overload2 = item.overload.split('_')[1] || 0
              item.overload3 = item.overload.split('_')[2] || 0
              item.overload4 = item.overload.split('_')[3] || 0
              item.arc = item.arc || '0_0'
              item.v46 = item.v46 || '0_0'
              item.arc1 = item.arc.split('_')[0] || 0
              item.arc2 = item.arc.split('_')[1] || 0
              item.v461 = item.v46.split('_')[0] || 0
              item.v462 = item.v46.split('_')[1] || 0
            })
          }
          this.total = res.total

          if (res.list && res.list[0] && res.list[0].deviceNew) {
            res.list[0].deviceNew.showSwitch =
              res.list[0].deviceNew.deviceState === '1'
            res.list[0].deviceNew.insideShowSwitch =
              res.list[0].deviceNew.insideDeviceState === '1'
          }
          this.detailInfo = res.list[0]
        }
      }).finally(() => {
        this.loading = false
      })
    },

    goHistory (deviceNum) {
      this.$router.push('/device/history?deviceNum=' + deviceNum)
    },

    onAdd () {
      this.$router.push({ name: 'deviceAdd', query: { showList: 1 } })
    },
    onEdit (id) {
      this.$router.push({
        name: 'deviceEdit',
        params: {
          id,
          showList: 1
        }
      })
    },

    onDelete (deviceNum) {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(this.$apis.device.deleteDevice, { deviceNum })
            .then(res => {
              this.$message.success('删除成功')
              this.page = 1
              this.getTableData()
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.el-input,
.el-select,
.el-cascader {
  width: 250px;
}
.my-table {
  margin-top: 15px;
}

.font-big {
  font-size: 16px;
  font-weight: 600;
}

.tips {
  font-size: 12px;
  color: #aaa;
  margin-left: 20px;
}

.org-item {
  display: flex;
  align-items: center;

  img {
    width: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}

.contrainer {
  display: flex;
  .contrainer-left {
    width: 260px;
    margin-right: 15px;
    min-height: calc(100vh - 90px);

    .card {
      height: 100%;
    }
  }
  .contrainer-right {
    flex: 1;
    min-width: 600px;
    min-height: calc(100vh - 90px);
  }
}
// ::v-deep .el-tree {
//   font-size: 14px;
//   .el-tree-node__content {
//     font-size: 14px;
//     height: 34px;
//     line-height: 34px;
//   }
//   .el-tree-node__children {
//     .el-tree-node .el-tree-node__content {
//       font-size: 14px;
//       margin: 4px 0;
//       height: 34px;
//       line-height: 34px;
//       padding-right: 18px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       max-width: 350px;
//     }
//   }
// }
.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    width: 100px;
    font-size: 14px;
    color: #999;
    text-align: right;
  }
  .value {
    flex: 1;
    font-size: 14px;
    color: #000;
    margin-left: 10px;
  }
}
.title-form {
  font-size: 14px;
  font-weight: 600;
  margin: 30px 0 20px 0;
  padding-left: 14px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
.child-form {
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
.close-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: #f40909;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(255, 0, 0, 0.8);
}
.open-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: green;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 255, 0, 0.8);
}
.on-line {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: green;
}
.off-line {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}

.row {
  display: flex;
  margin-bottom: 20px;
  font-size: 14px;
  .label {
    width: 150px;
    text-align: right;
  }
  .value {
    word-break: break-all;
    margin-left: 14px;
  }
}
.title-box {
  font-size: 14px;
  margin: 10px 0 30px 0;
  padding-left: 10px;
  position: relative;
  &.title-box2 {
    margin-top: 40px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
.content-table {
  width: 860px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-collapse: collapse;

  tr {
    td {
      font-size: 14px;
      line-height: 38px;
      display: inline-block;
      width: 25%;
      height: 38px;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      text-align: left;
      padding: 0 10px;
      &:nth-child(2n-1) {
        text-align: right;
      }

      .td-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.close-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: #f40909;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(255, 0, 0, 0.8);
}
.open-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: green;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 255, 0, 0.8);
}
.table-bx {
  display: flex;
  width: 100%;
  .content-table {
    width: 420px;
    tr {
      td {
        width: 50%;
      }
    }
  }
  .left {
    margin-right: 30px;
  }
}
</style>
